import * as React from 'react'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import { ModalFooter } from '@components/modals/modal-footer'
import { ModalBody } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { GastroCard, GastroGenericProductKind } from '@models/client'
import Decimal from 'decimal.js'
import { getGastroProductName } from '@modules/special-products/gastro-vouchers/utils'

interface Props {
  handleClose: () => void
  voucher: GastroCard
}

export const GastroGenericDetailsDescriptionModal = ({ handleClose, voucher }: Props): JSX.Element => {
  const availablePrice = new Decimal(voucher.declared_sell_price).mul(voucher.promotion_multiplier).toString()
  const productName = getGastroProductName(voucher)

  const informationForKind = voucher.kind.endsWith('_wholesale') ? voucher.kind.replace('_wholesale', '') : voucher.kind
  const benefits = gastroGenericInformation[informationForKind]

  return (
    <>
      <ModalBody>
        <p className="text-secondary fw-bold font-size-xl text-center text-sm-start">
          Co zyskujesz kupując Voucher <span className="text-nowrap">"{productName}"?</span>
        </p>
        <p className="font-size-sm text-center text-sm-start">
          W ramach Vouchera "{productName}" zwiększamy wpłaconą przez Ciebie kwotę, którą wykorzystasz w punktach
          gastronomicznych na terenie Resortu.
        </p>
        <div className="d-flex flex-wrap">
          {benefits.map(benefit => (
            <IconWithText
              key={benefit}
              text={benefit}
              iconClassName="uil-check me-2 text-primary font-size-lg"
              textClassName="font-size-sm"
              className="col-12"
            />
          ))}
        </div>

        <div className="d-flex flex-sm-row flex-column my-3 text-darker-gray mt-4">
          <div className="rounded bg-alice-blue px-4 pt-4 pb-5 col-sm-6 col-12 d-flex flex-wrap flex-column align-items-xl-start align-items-center justify-content-center justify-content-xl-between me-xl-1">
            <strong>Wpłacana kwota:</strong>
            <span className="d-block font-weight-semibold font-size-icon-sm text-primary">
              {formatPriceShort(voucher.declared_sell_price)}
            </span>
            <span className="opacity-75 font-size-sm">
              którą w ramach <br className="d-sm-block d-none" /> promocji <strong>zwiększamy</strong>
            </span>
          </div>
          <div className="rounded bg-tropical-blue p-4 col-6 text-sm-end text-start col-sm-6 col-12 d-flex flex-wrap flex-column align-items-center justify-content-between position-relative ms-xl-1">
            <div className="rounded gastro-card-details__factor">do</div>
            <strong className="mt-3 mt-sm-0">Wartość vouchera:</strong>
            <strong className="d-block font-size-icon-sm text-secondary"> = {formatPrice(availablePrice)}</strong>
            <span className="opacity-75 font-size-sm ps-xl-5 text-center text-xl-start">
              do wykorzystania <br className="d-sm-block d-none" /> w <strong>punktach gastronomicznych</strong>
            </span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter withCancelButton withSubmit={false} onClose={handleClose} cancelLabel="Zamknij" />
    </>
  )
}

const gastroGenericInformation: Partial<Record<GastroGenericProductKind, string[]>> = {
  gastro_coupon: [
    'Do nabycia wyłącznie w trakcie trwania rezerwacji',
    'Kontrola wydatków w aplikacji mobilnej',
    'Wszystkie punkty gastronomiczne na terenie Resortu',
  ],
  gastro_card_boost: [
    'Wszystkie punkty gastronomiczne na terenie Resortu',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2024 - 31.12.2025',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
  ],
  gastro_card_2024: [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2024 - 31.12.2024',
  ],
  gastro_card_2025: [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2025 - 31.12.2025',
  ],
  gastro_card_2026: [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2026 - 31.12.2027',
  ],
  gastro_card_2026_october: [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2026 - 31.12.2027',
  ],
  gastro_card_2026_november: [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2026 - 31.12.2027',
  ],
  gastro_card_2027: [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2027 - 31.12.2027',
  ],
  gastro_card_dynamic: [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku. Voucher nie uprawnia do korzystania z oferty gastronomicznej w Restauracji.',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2027 - 31.12.2027',
  ],
  gastro_card_black_week_2024: [
    'Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł',
    'Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego część aż do momentu całkowitego wykorzystania.',
    'Korzystaj w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku oraz w Restauracji!',
    'Voucher można wykorzystać podczas pobytów w terminie 01.01.2025 - 31.12.2026',
  ],
}
